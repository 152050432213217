<template>
  <div class="row">
    <div class="col-lg-4 col-md-8 col-12 mx-auto">
      <div class="card z-index-0 fadeIn1 fadeInBottom">
        <div
          v-if="title"
          class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
        >
          <div class="bg-gradient-primary shadow-success border-radius-lg py-3 pe-1">
            <h4 class="text-white font-weight-bolder text-center mt-2">
              {{ title }}
            </h4>
          </div>
        </div>
        <div class="card-body">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CenteredContent",

  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
